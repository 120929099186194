import React from "react";

import { Box, Typography, TextField, Checkbox, Button, styled } from "@mui/material";
import { AppLogo, AppLogo2, blankCircle, greenCheckCircle, helpIcon2, tickCheckBox, unCheckBox, circleTick } from "./assets";
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import 'react-toastify/dist/ReactToastify.css';
import './EmailAccountRegistrationWeb.css'
import { ToastContainer } from "react-toastify";

import EmailAccountRegistrationController, {
  Props,
} from "./EmailAccountRegistrationController.web";

export default class EmailAccountRegistrationBlock extends EmailAccountRegistrationController {
  constructor(props: Props) {
    super(props);
  }

  handlePasswordViewIcon = () => {
    return !this.state.togglePasswordView ? <VisibilityOffOutlinedIcon data-testid="handleTogglePassword" style={webStyle.passwordIcon} onClick={this.handleTogglePassword} /> : <VisibilityOutlinedIcon style={webStyle.passwordIcon} onClick={this.handleTogglePassword} data-testid="handleTogglePassword" />
  }

  handleValidityPasswordIcon = (isCheck: boolean) => {
    if (isCheck) {
      return <img src={greenCheckCircle.default} />
    } else {
      return <img src={blankCircle.default} />
    }
  }

  handleValidatePassword = (type: string) => {
    if (type === '1') {
      return <>{this.handleValidityPasswordIcon(this.state.passwordEightDigitValidate)} <span style={webStyle.passwordSpan}>Min. 8 characters</span></>
    } else if (type === '2') {
      return <>{this.handleValidityPasswordIcon(this.state.passwordOneNumberValidate)} <span style={webStyle.passwordSpan}>1 number</span></>
    } else if (type === '3') {
      return <>{this.handleValidityPasswordIcon(this.state.passwordCapitalCaseValidate)} <span style={webStyle.passwordSpan}>1 Capital letter</span></>
    } else {
      return <>{this.handleValidityPasswordIcon(this.state.passwordLowerCaseValidate)} <span style={webStyle.passwordSpan}>1 Lowercase letter</span></>
    }
  }

  handleRenderModal = () => {
    if (this.state.isModalOpen === true) {
      return (
        <FormBox>
          <Box
            width="586px"
            height="722px"
            bgcolor="rgba(235, 235, 235, 0.898)"
            borderRadius="16px"
            border="3px solid white"
            display='flex'
            flexDirection='column'
            justifyContent='start'
            alignItems='center'
          >
            <Box alignItems="center" display="flex" flexDirection="column">
              <Box display="flex" justifyContent="space-between" alignItems="center" width="586px" >
                <ChevronLeftIcon data-testid="closeModal" onClick={this.closeModal} style={{ fontSize: '2.5rem', marginBottom: '16px' }} />
                <Box display="flex" justifyContent="end" alignItems="center" marginTop='15px' marginRight='30px' marginBottom='30px' width="586px">
                  <img src={helpIcon2.default} />
                </Box>
              </Box>
              <Box display="flex" justifyContent="center" width="100%" >
                <img src={AppLogo2.default} />
              </Box>
            </Box>
            <Typography style={webStyle.logoText}>Terms and Conditions</Typography>
            <Box className="consent-paragraph" data-testid="handleScroll" ref={this.scrollRef} onScroll={this.handleScroll}>

              <p style={{
                textAlign: 'center',
              }} dangerouslySetInnerHTML={{ __html: this.state.termsPolicyData }}>
              </p>
            </Box>
            <Button
              data-testid="handleAccept"
              style={{
                ...webStyle.acceptBtn,
                ...(this.state.isScrollCompleted ? webStyle.acceptBtnCompleted : {}),
              }}
              onClick={this.handleAccept}
            >Accept</Button>
          </Box>
        </FormBox>
      )
    } else {
      if (this.state.isCreateAccount) {
        return (
          <>
            <FormBox>
              <Box
                width="586px"
                height="722px"
                bgcolor="rgba(235, 235, 235, 0.898)"
                borderRadius="16px"
                border="3px solid white"
                display='flex'
                flexDirection='column'
                justifyContent='start'
                alignItems='center'
              >

                <Box alignItems="center" display="flex" flexDirection="column" marginBottom='26px'>
                  <Box display="flex" justifyContent="end" alignItems="center" marginTop='15px' marginRight='30px' marginBottom='30px' width="586px" >
                    <img src={helpIcon2.default} />
                  </Box>
                  <Box display="flex" justifyContent="center" width="100%" marginTop="10px" >
                    <img src={AppLogo2.default} />
                  </Box>
                </Box>
                <Box width='433px' display='flex' flexDirection="column" justifyContent='center' alignItems='center'>
                  <Typography style={webStyle.logoText}>Verify your email address</Typography>
                  <Typography style={webStyle.signupText}>Thank you for joining Silenium. A verification email has been sent to the provided email address. Please check <br /> your inbox and click on the verification link to complete <br /> the signup process.</Typography>
                  <Box style={webStyle.circleTick}>
                    <img src={circleTick.default} />
                  </Box>
                  <Button
                    style={webStyle.goLoginbtn}
                    onClick={this.handleGoToLogin}
                  >
                    Go to Log in
                  </Button>
                </Box>
              </Box>

            </FormBox>
          </>
        );
      } else {
        return (
          <FormBox>
            <Box
              width="586px"
              height="722px"
              bgcolor="rgba(235, 235, 235, 0.898)"
              borderRadius="16px"
              border="3px solid white"
              display='flex'
              flexDirection='column'
              justifyContent='start'
              alignItems='center'
            >
              <Box alignItems="center" display="flex" flexDirection="column">
                <Box display="flex" justifyContent="end" alignItems="center" marginTop='15px' marginRight='30px' marginBottom='30px' width="586px" >

                  <img src={helpIcon2.default} />
                </Box>
                <Box display="flex" justifyContent="center" width="100%" >
                  <img src={AppLogo2.default} />
                </Box>
              </Box>

              <Typography style={webStyle.logoText}>Welcome back</Typography>
              <Typography style={webStyle.signupText}>Signup your account</Typography>

              <Box display="flex" gap="24px" justifyContent="center" width='30rem' marginTop='10px' marginBottom='20px'>
                <Box style={webStyle.boxStyle}>
                  <Typography style={webStyle.labelStyle}>First Name</Typography>
                  <TextField
                    data-testid="handleFirstNameChange"
                    placeholder="First name"
                    style={webStyle.textInput}
                    value={this.state.firstName}
                    onChange={this.handleFirstNameChange}
                  />
                </Box>
                <Box style={webStyle.boxStyle}>
                  <Typography style={webStyle.labelStyle}>Last Name</Typography>
                  <TextField
                    data-testid="handleLastNameChange"
                    placeholder="Last name"
                    style={webStyle.textInput}
                    value={this.state.lastName}
                    onChange={this.handleLastNameChange}
                  />
                </Box>
              </Box>

              <Box display="flex" justifyContent='center' width='30rem' marginBottom='20px'>
                <Box style={webStyle.boxStyle} width="100%">
                  <Typography style={webStyle.labelStyle}>Email</Typography>
                  <TextField
                    data-testid="handleEmailChange"
                    type="email"
                    placeholder="Email"
                    fullWidth
                    value={this.state.email}
                    style={webStyle.textInput}
                    onChange={this.handleEmailChange}
                  />
                </Box>
              </Box>

              <Box display="flex" justifyContent='center' width='30rem' marginBottom='20px'>
                <Box style={webStyle.boxStyle} width="100%">
                  <Typography style={webStyle.labelStyle}>Password</Typography>
                  <TextField
                    data-testid="handleChangePassword"
                    type={this.returnPasswordType()}
                    placeholder="Enter your password"
                    fullWidth
                    style={webStyle.textInput}
                    value={this.state.password}
                    onChange={this.handleChangePassword}
                    InputProps={{ endAdornment: this.handlePasswordViewIcon() }}
                    inputProps={{ maxLength: 64 }}
                  />

                </Box>
              </Box>

              <Box width="30rem" display='flex' alignItems='center' marginBottom='20px'>
                <Typography style={webStyle.passwordValidate}>{this.handleValidatePassword('1')}</Typography>
                <Typography style={webStyle.passwordValidate}>{this.handleValidatePassword('2')}</Typography>
                <Typography style={webStyle.passwordValidate}>{this.handleValidatePassword('3')}</Typography>
                <Typography style={webStyle.passwordValidate}>{this.handleValidatePassword('4')}</Typography>
              </Box>

              <Box display="flex" flexDirection="column" justifyContent='center' alignItems="center" mt="10px" gap="16px" marginBottom="20px">
                <Box display={"flex"} alignItems={"start"} width='full' mr={"1.75rem"}>
                  <Checkbox style={{ padding: "0px" }} icon={<img src={unCheckBox.default} />} checkedIcon={<img src={tickCheckBox.default} onClick={this.handleCheckboxChange} />} checked={this.state.isChecked} onClick={this.handleCheckboxChange} />

                  <Typography style={webStyle.consentText}>I hereby consent to the <b style={{ textDecoration: "underline" }}>Privacy Policy</b> and <b data-testid="handleCheckboxChange" onClick={this.handleCheckboxChange} style={{ textDecoration: "underline", cursor: "pointer", }}>Terms and Conditions</b></Typography>
                </Box>
                <Button
                  data-testid="createAccountSignUp"
                  style={{ ...webStyle.createButton, ...(!this.handleCheckedValidate() ? webStyle.activeCreateButton : {}) }}
                  disabled={this.handleDisabledAccountBtn()}
                  onClick={this.createAccountSignUp}
                >
                  Create account
                </Button>

                <Typography data-testid="handleGoToLogin" onClick={this.handleGoToLogin} style={webStyle.consentText}>Already have an account? <b style={{ textDecoration: "underline", cursor: "pointer" }}>Log in</b></Typography>
              </Box>
            </Box>
          </FormBox >
        );
      }
    }

  };
  render() {
    return (
      <>
        <Box width="100%" height="100%" bgcolor="#00184C" display="flex" alignItems="center" fontStyle='inter'>
          <ToastContainer style={{ maxWidth: '607px', minWidth: "fit-content" }} />
          <Box width="50%" display="flex" justifyContent="center">
            <img src={AppLogo.default} style={{ width: "436px", height: "102px" }} />
          </Box>
          {this.handleRenderModal()}

        </Box>
      </>
    );
  }
}

// fix bugs related to UI of other experts.
const FormBox = styled(Box)({
  width: '50%',

  "@media (max-height: 800px)": {
    scale: 0.95
  },
  "@media (max-height: 750px)": {
    scale: 0.9
  },
  "@media (max-height: 700px)": {
    scale: 0.85
  },
  "@media (max-height: 650px)": {
    scale: 0.75
  },
});

const webStyle = {
  boxStyle: {
    display: "flex",
    flexDirection: "column" as "column",
    flex: 1,
  },
  logoText: {
    fontWeight: 700,
    fontSize: '30px',
    paddingTop: '35px',
    paddingBottom: '8px',
    height: "40px",
    color: "#22223E",
    textAlign: 'center' as 'center',
    fontStyle: 'inter',
  },
  signupText: {
    color: '#334155',
    textAlign: 'center' as 'center',
    fontSize: "16px",
    fontStyle: 'inter',
    fontWeight: 400,
    lineHeight: '24px',

  },
  helpIcon: {
    position: "absolute" as "absolute",
    right: "18.33px",
    top: "20.33px",
    width: "23.33px",
    height: '23.33px'
  },
  textInput: {
    border: "1px solid #334155",
    backgroundColor: "white",
    borderRadius: "8px"
  },
  labelStyle: {
    color: '#334155',
    fontSize: '14px',
    fontWeight: 700 as 700,
    paddingBottom: '4px',
  },
  passwordIcon: {
    color: '#334155', cursor: "pointer"
  },
  passwordValidate: {
    color: '#22223E',
    fontSize: "12px",
    fontWeight: 400 as 400,
    height: "18px",
    display: 'flex',
    alignItems: "center"
  },
  passwordSpan: {
    paddingTop: '2px',
    paddingLeft: '6px',
    paddingRight: "10px"
  },
  consentCheckbox: {
    display: 'flex',
    justifyContent: 'center',
    alignItem: 'center',
    color: "#334155"
  },
  createButton: {
    width: '270px',
    height: '44px',
    border: '2px solid #00184C',
    borderRadius: '24px',
    color: '#00184C',
    textContent: 'center',
    textTransform: "capitalize" as "capitalize"
  },
  activeCreateButton: {
    color: 'white',
    backgroundColor: '#00184C'
  },
  acceptBtn: {
    marginTop: '40px',
    width: '207',
    height: '44px',
    border: '2px solid #00184C',
    borderRadius: '24px',
    color: '#00184C',
    textContent: 'center',
    fontWeight: 700 as 700,
    textTransform: "capitalize" as "capitalize"
  },
  acceptBtnCompleted: {
    backgroundColor: '#00184C',
    color: 'white'
  },
  consentText: {
    color: "#334155",
    fontSize: "14px",
    paddingLeft: "6px"
  },
  circleTick: {
    marginTop: '30px',
    marginBottom: '20px'
  },
  goLoginbtn: {
    marginTop: '40px',
    width: '207',
    height: '44px',
    border: '2px solid #00184C',
    borderRadius: '24px',
    textContent: 'center',
    fontWeight: 700 as 700,
    textTransform: "capitalize" as "capitalize",
    backgroundColor: '#00184C',
    color: 'white',
    cursor: "pointer"
  }
}
