import React from "react";

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { AppLogo, circleTick, FormLogo, helpIcon } from "./assets";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { Box, Typography, TextField, Button, styled } from "@mui/material";
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';



import ForgotPasswordController, {
  Props,
} from "./ForgotPasswordController.web";

export default class NewPassword extends ForgotPasswordController {
  constructor(props: Props) {
    super(props);
  }

  handleValidatePassword = (type: string) => {
    if (type === '1') {
      return <>{this.handleValidityPasswordIcon(this.state.passwordEightDigitValidate)} <span style={webStyle.passwordSpan}>Min. 8 characters</span></>
    } else if (type === '2') {
      return <>{this.handleValidityPasswordIcon(this.state.passwordOneNumberValidate)} <span style={webStyle.passwordSpan}>1 number</span></>
    } else if (type === '3') {
      return <>{this.handleValidityPasswordIcon(this.state.passwordCapitalCaseValidate)} <span style={webStyle.passwordSpan}>1 Capital letter</span></>
    } else {
      return <>{this.handleValidityPasswordIcon(this.state.passwordLowerCaseValidate)} <span style={webStyle.passwordSpan}>1 Lowercase letter</span></>
    }
  }

  handlePasswordViewIcon = () => {
    return !this.state.togglePasswordView ? <VisibilityOffOutlinedIcon data-testid="handleTogglePassword" style={webStyle.passwordIcon} onClick={this.handleTogglePassword} /> : <VisibilityOutlinedIcon style={webStyle.passwordIcon} onClick={this.handleTogglePassword} data-testid="handleTogglePassword" />
  }

  handleConfirmPasswordViewIcon = () => {
    return <>{this.handleErrorPassword()}{!this.state.toggleConfirmPasswordView ? <VisibilityOffOutlinedIcon data-testid="handleTogglePassword" style={webStyle.passwordIcon}
      onClick={this.handleToggleConfirmPassword} /> : <VisibilityOutlinedIcon style={webStyle.passwordIcon} onClick={this.handleToggleConfirmPassword}
        data-testid="handleTogglePassword" />
    }</>
  }

  handleErrorPassword = () => {
    if (this.state.newPasswordError !== '') {
      return <>
        <CloseRoundedIcon style={{ color: "red", marginRight: '4px' }} />
      </>
    }
  }

  handleConfirmPage = () => {
    if (this.state.confirmationPage === true) {
      return (
        <>
          <Typography style={webStyle.signupText}>Congrat’s your password has been set.</Typography>
          <Box width='433px' display='flex' flexDirection="column" justifyContent='center' alignItems='center'>
            <Box style={webStyle.circleTick}>
              <img src={circleTick.default} />
            </Box>
            <Button
              style={webStyle.goLoginbtn}
              onClick={this.handleGoToLogin}
            >
              Go to Log in
            </Button>
          </Box>
        </>)
    } else {
      return (
        <>
          <Typography style={webStyle.signupText}>Please set your new password.</Typography>
          <Box style={webStyle.boxStyle}>
            <Typography style={webStyle.labelStyle}>Password</Typography>
            <TextField
              data-testid="handlePasswordChange"
              placeholder="Enter your password"
              type={this.state.togglePasswordView ? "text" : "password"}
              sx={webStyle.textInput}
              value={this.state.password}
              onChange={this.handlePasswordChange}
              InputProps={{ endAdornment: this.handlePasswordViewIcon() }}
              inputProps={{ maxLength: 64 }}
            />
          </Box>
          <Box display='flex' justifyContent='center' alignItems='center' marginLeft='22px' marginTop='20px' marginBottom='20px'>
            <Typography style={webStyle.passwordValidate}>{this.handleValidatePassword('1')}</Typography>
            <Typography style={webStyle.passwordValidate}>{this.handleValidatePassword('2')}</Typography>
            <Typography style={webStyle.passwordValidate}>{this.handleValidatePassword('3')}</Typography>
            <Typography style={webStyle.passwordValidate}>{this.handleValidatePassword('4')}</Typography>
          </Box>
          <Box style={webStyle.boxStyle} mt={"10px!important"}>
            <Typography style={webStyle.labelStyle}>Confirm new password</Typography>
            <TextField
              data-testid="handleConfirmPassword"
              placeholder="Confirm your new password"
              type={this.state.toggleConfirmPasswordView ? "text" : "password"}
              sx={webStyle.textInput}
              value={this.state.confirmPassword}
              onChange={this.handleConfirmPassword}
              InputProps={{ endAdornment: this.handleConfirmPasswordViewIcon() }}
              inputProps={{ maxLength: 64 }}
            />
            <Typography style={webStyle.newPasswordError}>{this.state.newPasswordError}</Typography>
          </Box>
          <Button
            data-testid="handleSetNewPassword"
            style={{
              ...webStyle.acceptBtn,
              ...(this.state.activeNewPasswordBtn ? webStyle.acceptBtnCompleted : {}),
            }}
            disabled={!this.state.activeNewPasswordBtn}
            onClick={this.handleSetNewPassword}
          >
            Set your new password
          </Button>
        </>)
    }
  }
  render() {
    return (
      <>
        <Box width="100%" height="100%" bgcolor="#00184C" display="flex" alignItems="center" fontStyle='inter'>
          <ToastContainer style={{ maxWidth: '607px', minWidth: "fit-content" }} />
          <Box width="50%" display="flex" justifyContent="center">
            <img src={AppLogo.default} style={{ width: "436px", height: "102px" }} />
          </Box>
          <FormBox1 width="50%">
            <Box
              width="586px"
              height="722px"
              bgcolor="rgba(235, 235, 235, 0.898)"
              borderRadius="16px"
              border="3px solid white"
              display='flex'
              flexDirection='column'
              justifyContent='start'
              alignItems='center'
            >
              <Box alignItems="center" display="flex" flexDirection="column">
                <Box display="flex" justifyContent="space-between" alignItems="center" width="586px" >
                  <ChevronLeftIcon data-testid="closeModal" onClick={this.closeModal} style={{ fontSize: '2.5rem', marginBottom: '16px' }} />
                  <Box display="flex" justifyContent="end" alignItems="center" marginTop='15px' marginRight='15px' marginBottom='30px' width="586px">
                    <img src={helpIcon.default} />
                  </Box>
                </Box>
                <Box display="flex" justifyContent="center" width="100%" >
                  <img src={FormLogo.default} />
                </Box>
              </Box>
              <Typography style={webStyle.logoText}>Forgot password</Typography>
              {this.handleConfirmPage()}

            </Box>
          </FormBox1>

        </Box>
      </>
    );
  }
}

// fix bugs related to UI of other experts.
const FormBox1 = styled(Box)({
	width: '50%',
  
	"@media (max-height: 800px)": {
	  scale: 0.95,
	},
	"@media (max-height: 750px)": {
	  scale: 0.9,
	},
	"@media (max-height: 700px)": {
	  scale: 0.85,
	},
	"@media (max-height: 650px)": {
	  scale: 0.75,
	},
  });

const webStyle = {
  boxStyle: {
    display: "flex",
    flexDirection: "column" as "column",
    width: '30rem',
    height: '100px',
    marginTop: '64px'
  },
  logoText: {
    fontWeight: 700,
    fontSize: '30px',
    paddingTop: '35px',
    paddingBottom: '8px',
    height: "40px",
    color: "#22223E",
    textAlign: 'center' as 'center',
    fontStyle: 'inter',
  },
  signupText: {
    color: '#334155',
    textAlign: 'center' as 'center',
    fontSize: "16px",
    fontStyle: 'inter',
    fontWeight: 400,
    lineHeight: '24px',

  },
  helpIcon: {
    position: "absolute" as "absolute",
    right: "18.33px",
    top: "20.33px",
    width: "23.33px",
    height: '23.33px'
  },
  textInput: {
    border: "1px solid #334155",
    backgroundColor: "white",
    borderRadius: "8px",
    '& .MuiInputBase-input': {
      color: "#334155",
    },
  },
  labelStyle: {
    color: '#334155',
    fontSize: '14px',
    fontWeight: 700 as 700,
    paddingBottom: '4px',
  },
  acceptBtn: {
    marginTop: '70px',
    width: '207',
    height: '44px',
    border: '2px solid #00184C',
    borderRadius: '24px',
    color: '#00184C',
    textContent: 'center',
    fontWeight: 700 as 700,
    textTransform: "capitalize" as "capitalize"
  },
  acceptBtnCompleted: {
    backgroundColor: '#00184C',
    color: 'white'
  },
  passwordValidate: {
    color: '#22223E',
    fontSize: "12px",
    fontWeight: 400 as 400,
    height: "18px",
    display: 'flex',
  },

  passwordSpan: {
    paddingTop: '2px',
    paddingLeft: '6px',
    paddingRight: "18px"
  },
  passwordIcon: {
    color: '#334155', cursor: "pointer"
  },
  newPasswordError: {
    color: 'rgba(220, 38, 38, 1)',
    textAlign: 'right' as 'right',
    fontWeight: 400,
    marginTop: '2px'
  },
  circleTick: {
    marginBlock: '70px',
  },
  goLoginbtn: {
    width: '207',
    height: '44px',
    border: '2px solid #00184C',
    borderRadius: '24px',
    textContent: 'center',
    fontWeight: 700 as 700,
    textTransform: "capitalize" as "capitalize",
    backgroundColor: '#00184C',
    color: 'white',
    cursor: "pointer"
  }
}
