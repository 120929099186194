import {
  Route,
  Routes,
  useNavigate,
  useParams,
  useLocation,
  useSearchParams,
  Navigate,
} from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { useRunEngine } from '../../../blocks/utilities/src/hooks/useRunEngine';

import { Popover } from '@builder/component-library';

import { Params } from "./types";
import { sendRunEngineNavigationEvent } from "./helpers";

interface IWrapper {
  element: JSX.Element;
  isModal: boolean;
  routeMap: IWebRoutesGenerator;
  childRouteMap: IWebRoutesGenerator | undefined;
  route: string;
  navigationStackTrace: string[];
  complexParams: any;
  setComplexParams: React.Dispatch<any>;
}

interface IScreen {
  component: React.ElementType;
  path: string;
  modal: boolean;
  exact: boolean;
  screens: IWebRoutesGenerator;
}

interface FindScreen {
  url: string;
  route: IScreen;
  params?: Params;
}

interface NavigationToFlatList {
  list: string[];
  params: Params;
}

interface IWebRoutesGenerator {
  [key: string]: IScreen;
}

function Wrapper({
  element,
  isModal,
  routeMap,
  childRouteMap,
  route,
  navigationStackTrace,
  complexParams,
  setComplexParams,
}: IWrapper) {
  const _navigate = useNavigate();
  const _params = useParams();
  const [queryParams, _] = useSearchParams();
  const [params, setInternalParams] = useState<Params>({
    path: _params,
    ...queryParams,
  });
  const location = useLocation();
  const [listeners, setListeners] =
    useState<Map<string, (item: any) => void>>();
  const { sendMessage } = useRunEngine();

  useEffect(() => {
    setInternalParams((p: Params) => {
      return { ...p, ...queryParams };
    });
  }, [queryParams]);

  useEffect(() => {
    setInternalParams((p: Params) => {
      return { ...p, path: { ..._params } };
    });
  }, [_params]);

  useEffect(() => {
    sendRunEngineNavigationEvent(sendMessage, location.pathname, route);
  }, [location, route, sendMessage]);

  const state = {
    key: location.key,
    routeName: location.pathname,
    params: params,
  };

  const findChildRoute = (to: string): FindScreen | undefined => {
    const currentRoute = childRouteMap?.[to];
    if (currentRoute?.screens) {
      const firstChildRoute =
        currentRoute.screens[Object.keys(currentRoute.screens)[0]];
      if (!firstChildRoute) {
        return;
      }
      return {
        url: firstChildRoute.path,
        route: firstChildRoute,
      };
    }
  };

  const findCurrentRoute = (to: string): FindScreen | undefined => {
    const childRoute = childRouteMap?.[to];
    if (childRoute) {
      return { url: childRoute.path, route: childRoute };
    }
    const currentRoute = routeMap?.[to];
    if (currentRoute) {
      return { url: currentRoute.path, route: currentRoute };
    }
  };

  const findParentRoutesRecursively = (
    to: string,
    stackTrace?: string[] | undefined
  ): FindScreen | undefined => {
    var _routeMap: IWebRoutesGenerator | IScreen = routeMap;
    if (stackTrace === undefined) {
      stackTrace = [...navigationStackTrace];
    }
    if (stackTrace.length === 0) {
      return undefined;
    }
    stackTrace.pop();
    var stackRouteMap: IWebRoutesGenerator = _routeMap as IWebRoutesGenerator;
    stackTrace.forEach((item: string) => {
      const iScreen = stackRouteMap?.[item];
      if (iScreen) {
        _routeMap = iScreen;
      }
    });
    const stackIScreenRouteMap = stackRouteMap[to];
    if (stackIScreenRouteMap) {
      return { url: stackIScreenRouteMap.path, route: stackIScreenRouteMap };
    }
    const routeMapUnknown = _routeMap as unknown;
    const iScreenRouteMap: IScreen = routeMapUnknown as IScreen;
    const iScreenRouteMapTo = iScreenRouteMap?.screens?.[to];
    if (iScreenRouteMapTo) {
      return {
        url: iScreenRouteMapTo.path,
        route: iScreenRouteMapTo,
      };
    }

    return findParentRoutesRecursively(to, stackTrace);
  };

  const attachParamsToUrl = (
    url: string,
    params: Params | undefined
  ): string => {
    if (params?.path && Object.keys(params?.path).length > 0) {
      Object.keys(params?.path).forEach((param) => {
        const re = RegExp(`\:${param}\\??`);
        url = url.replace(re, escape(params?.path?.[param] || ""));
      });
    }
    if (params && Object.keys(params).length > 0) {
      url += "?";
      let _complexParams: any = {};
      Object.keys(params).map((param) => {
        if (param === "path") {
          return;
        }

        if (typeof params[param] === "object") {
          _complexParams[param] = params[param];
          return;
        }

        url += `${param}=${encodeURIComponent(params[param])}&`;
      });
      setComplexParams(_complexParams);

      url = url.slice(0, -1);
    }
    return url;
  };

  const findBasicRoutes = (to: string) => {
    var routes = findChildRoute(to);
    if (routes) {
      return routes;
    }
    routes = findCurrentRoute(to);
    if (routes) {
      return routes;
    }

    return findParentRoutesRecursively(to);
  };

  const navigationPathToFlatList = (
    params: Params,
    list?: string[]
  ): NavigationToFlatList => {
    if (list === undefined) {
      list = [];
    }
    if (params?.screen) {
      list.push(params.screen);
      if (params?.params) {
        return navigationPathToFlatList(params.params, list);
      }
    }
    return { list, params };
  };

  const findRoute = (to: string, params: Params) => {
    var routes = findBasicRoutes(to);
    if (!routes) {
      return;
    }
    if (params?.screen && routes) {
      const navigationPath = navigationPathToFlatList(params);
      const result = navigationPath.list.reduce(
        (acc, to) => {
          if (acc.error) {
            return acc;
          }
          const iScreen = acc.routes.route?.screens[to];
          if (iScreen) {
            acc.routes.route = iScreen;
          } else {
            acc.error = true;
          }
          return acc;
        },
        { routes, error: false }
      );

      if (result.error) {
        return;
      }
      return {
        url: result.routes.route.path,
        params: navigationPath.params,
        route: result.routes.route,
      };
    }

    routes.params = params;
    return routes;
  };

  const getUrl = (to: string, _params: Params) => {
    var _routes = findRoute(to, _params);
    if (!_routes) {
      console.error('error no url found!');
      return;
    }
    var { url, route, params } = _routes;
    url = attachParamsToUrl(url, params);
    return { url: url.replace(/\/:(.*?)(?=\/|$)/g, ""), route };
  };

  const navigate = (to: string, params: Params) => {
    const urlDetails = getUrl(to, params);
    if (!urlDetails) {
      return;
    }
    const { url, route } = urlDetails;

    if (route?.modal) {
      _navigate(url, { state: { background: location } });
    } else {
      _navigate(url);
    }
  };

  const replace = (to: string, params: Params) => {
    const urlDetails = getUrl(to, params);
    if (!urlDetails) {
      return;
    }
    _navigate(urlDetails.url, { replace: true });
  };

  const setParams = (updatedParams: Params) => {
    const _params = { ...params, ...updatedParams };
    _navigate(attachParamsToUrl(location.pathname, _params));
    setInternalParams(_params);
  };

  const getParam = (param: string): string | undefined => {
    const _params: any = state?.params;
    if (_params[param]) {
      return _params[param];
    }
    if (complexParams?.[param]) {
      return complexParams[param];
    }
    return _params?.path[param];
  };

  const goBack = () => {
    _navigate(-1);
  };

  const addListener = (val: string, func: (event: string) => void) => {
    let event = listeners ? listeners : new Map<string, (item: any) => void>();
    event?.set(val, func);
    setListeners(event);
    return () => {
      removeListener(val);
    };
  };

  const removeListener = (eventVal: string) => {
    if (listeners?.get(eventVal)) {
      let item = listeners;
      item.delete(eventVal);
      setListeners(item);
    }
  };

  useEffect(() => {
    if (listeners) {
      Object.keys(listeners).map((key) => {
        if (Object.values(location).includes(key)) {
          listeners.get(key)?.(key);
        }
      });
    }
    if(window.location.pathname === '/'){
      _navigate("/EmailAccountLoginBlock")
    }
  }, [location, listeners]);

  let canGoBack = location !== undefined;

  const getState = () => {
    return state;
  };

  const pop = (i = -1) => {
    _navigate(i);
  };
  const popToTop = () => {
    let pathName = location.pathname;
    let index = pathName.lastIndexOf('/');
    pathName = pathName.substring(0, index ? index : 1);
    _navigate(pathName);
  };

  element = React.cloneElement(element, {
    navigation: {
      navigate,
      getParam,
      setParams,
      goBack,
      replace,
      state: state,
      getState,
      push: navigate,
      pop,
      popToTop,
      canGoBack : () => canGoBack,
      addListener,
    },
    route: {
      key: location.key,
      name: location.pathname,
      params: params,
      path: location.pathname,
    },
  });

  if (isModal) {
    element = (
      <Popover isVisible={true} onPressCloseIcon={goBack}>
        {element}
      </Popover>
    );
  }

  return element;
}

type Props = {
  routeMap: IWebRoutesGenerator;
};

export const WebRoutesGenerator = (props: Props) => {
  const [complexParams, setComplexParams] = useState<any | undefined>(
    undefined
  );
  return (
    <Routes>
      {generateRoutes(props.routeMap, complexParams, setComplexParams)}
    </Routes>
  );
};

const generateRoutes = (
  routeMap: IWebRoutesGenerator,
  complexParams: any,
  setComplexParams: React.Dispatch<any>
) => {
  return Object.keys(routeMap).map((route) => {
    const currentRoute = routeMap[route];
    if(currentRoute.path === '/'){
      <Navigate to={"/EmailAccountLoginBlock"}/>
    }
    if (!currentRoute) {
      return <></>;
    }
    const Component = currentRoute.component;
    let children: JSX.Element[] | undefined = undefined;

    const navigationStackTrace = [route];
    if (currentRoute.screens) {
      children = generateChildRoutes(
        routeMap,
        currentRoute.screens,
        navigationStackTrace,
        complexParams,
        setComplexParams
      );
    }
    return (
      <Route
        key={currentRoute.path}
        path={currentRoute.path}
        element={
          !children ? (
            <Wrapper
              element={<Component />}
              routeMap={routeMap}
              isModal={currentRoute.modal}
              route={route}
              navigationStackTrace={navigationStackTrace}
              childRouteMap={undefined}
              complexParams={complexParams}
              setComplexParams={setComplexParams}
            />
          ) : undefined
        }
      >
        {children}
      </Route>
    );
  });
};

const generateChildRoutes = (
  routeMap: IWebRoutesGenerator,
  childRouteMap: IWebRoutesGenerator,
  navigationStackTrace: string[],
  complexParams: any,
  setComplexParams: React.Dispatch<any>
) => {
  return Object.keys(childRouteMap).map((route) => {
    const currentRoute = childRouteMap[route];
    if (!currentRoute) {
      return <></>;
    }
    const Component = currentRoute.component;
    let children: JSX.Element[] | undefined = undefined;
    const stackTrace = [...navigationStackTrace, route];
    
    if (currentRoute.screens) {
      children = generateChildRoutes(
        routeMap,
        currentRoute.screens,
        stackTrace,
        complexParams,
        setComplexParams
      );
    }
    return (
      <Route
        key={currentRoute.path}
        path={currentRoute.path}
        element={
          !children ? (
            <Wrapper
              element={<Component />}
              childRouteMap={childRouteMap}
              routeMap={routeMap}
              isModal={currentRoute.modal}
              route={route}
              navigationStackTrace={navigationStackTrace}
              complexParams={complexParams}
              setComplexParams={setComplexParams}
            />
          ) : undefined
        }
      >
        {children}
      </Route>
    );
  });
};
