import React from "react";

import { Box, Typography, TextField, Button, styled } from "@mui/material";
import { AppLogo, FormLogo, helpIcon } from "./assets";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from "react-toastify";

import ForgotPasswordController, {
	Props,
} from "./ForgotPasswordController.web";

export default class ForgotPassword extends ForgotPasswordController {
	constructor(props: Props) {
		super(props);
	}

	render() {
		return (
			<>
				<Box width="100%" height="100%" bgcolor="#00184C" display="flex" alignItems="center" fontStyle='inter'>
					<ToastContainer style={{ maxWidth: '607px', maxHeight: '63px', minWidth: "fit-content" }} />
					<Box width="50%" display="flex" justifyContent="center">
						<img src={AppLogo.default} style={{ width: "436px", height: "102px" }} />
					</Box>
					<FormBoxWrapper width="50%">
						<Box
							width="586px"
							height="722px"
							bgcolor="rgba(235, 235, 235, 0.898)"
							borderRadius="16px"
							border="3px solid white"
							display='flex'
							flexDirection='column'
							justifyContent='start'
							alignItems='center'
						>
							<Box alignItems="center" display="flex" flexDirection="column">
								<Box display="flex" justifyContent="space-between" alignItems="center" width="586px" >
									<ChevronLeftIcon data-testid="closeModal" onClick={this.closeModal} style={{ fontSize: '2.5rem', marginBottom: '16px', cursor:"pointer" }} />
									<Box display="flex" justifyContent="end" alignItems="center" marginTop='15px' marginRight='15px' marginBottom='30px' width="586px">
										<img src={helpIcon.default} />
									</Box>
								</Box>
								<Box display="flex" justifyContent="center" width="100%" >
									<img src={FormLogo.default} />
								</Box>
							</Box>
							<Typography style={webStyle.logoText}>Forgot password</Typography>
							<Typography style={webStyle.signupText}>Please enter your email address to <br /> request a password reset.</Typography>
							<Box style={webStyle.boxStyle}>
								<Typography style={webStyle.labelStyle}>Email</Typography>
								<TextField
									data-testid="handleEmailChange"
									placeholder="Enter your email"
									type="email"
									style={webStyle.textInput}
									value={this.state.email}
									onChange={this.handleEmailChange}
								/>
							</Box>
							<Button
								data-testid="handleSentOtp"
								style={{
									...webStyle.acceptBtn,
									...(this.state.activeBtn ? webStyle.acceptBtnCompleted : {}),
								}}
								onClick={this.handleSentOtp}
								disabled={!this.state.activeBtn}
							>
								Send request
							</Button>

						</Box>
					</FormBoxWrapper>

				</Box>
			</>
		);
	}
}

// fix bugs related to UI of other experts.
const FormBoxWrapper = styled(Box)({
	width: '50%',
  
	"@media (max-height: 801px)": {
	  scale: 0.95
	},
	"@media (max-height: 751px)": {
	  scale: 0.9
	},
	"@media (max-height: 701px)": {
	  scale: 0.85
	},
	"@media (max-height: 651px)": {
	  scale: 0.75
	},
  });

const webStyle = {
	boxStyle: {
		display: "flex",
		flexDirection: "column" as "column",
		width: '30rem',
		marginTop: '64px'
	},
	logoText: {
		fontWeight: 700,
		fontSize: '30px',
		paddingTop: '35px',
		paddingBottom: '8px',
		height: "40px",
		color: "#22223E",
		textAlign: 'center' as 'center',
		fontStyle: 'inter',
	},
	signupText: {
		color: '#334155',
		textAlign: 'center' as 'center',
		fontSize: "16px",
		fontStyle: 'inter',
		fontWeight: 400,
		lineHeight: '24px',

	},
	helpIcon: {
		position: "absolute" as "absolute",
		right: "18.33px",
		top: "20.33px",
		width: "23.33px",
		height: '23.33px'
	},
	textInput: {
		border: "1px solid #334155",
		backgroundColor: "white",
		borderRadius: "8px"
	},
	labelStyle: {
		color: '#334155',
		fontSize: '14px',
		fontWeight: 700 as 700,
		paddingBottom: '4px',
	},
	acceptBtn: {
		marginTop: '140px',
		width: '207',
		height: '44px',
		border: '2px solid #00184C',
		borderRadius: '24px',
		color: '#00184C',
		textContent: 'center',
		fontWeight: 700 as 700,
		textTransform: "capitalize" as "capitalize"
	},
	acceptBtnCompleted: {
		backgroundColor: '#00184C',
		color: 'white'
	},
}
